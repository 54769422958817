import {graphql} from "gatsby";
import React from "react";

import Attachments from "../components/Attachments/Attachments";
import Layout from "../components/common/layout/layout";
import {Contact} from "../components/Forms/Contact/Contact";
import withForm from "../components/Forms/withForm";
// import Hero from "../components/Hero/Hero";
import Map from "../components/Map/Map";
import SEO from "../components/SEO/SEO";
import Procedures from "../components/Tramites/Procedures";
import PcisBanners from "../PCIS/components/PCIsBanner/PcisBanners";
import LazyIframe from "../components/common/iframe/lazyIframe";

const PageOrientacionProfesional = ({data}) => {
    const ContactWithForm = withForm(Contact, "orientación profesional");
    return (
        <>
        <SEO
            title={"Orientación Profesional PCI IES Ramón y Cajal"}
            canonical={"orientacion-profesional"}
            description={""}
            robots={"index, follow"}
        />
        <Layout>
            {/*<Hero title={"Orientación Profesional PCI IES Ramón y Cajal"}/>*/}
            <div className={"o-pci"}>
                <main>
                <section className={"container"}>
                    <div className="grid-x2 grid--bottom grid--break-pro">
                        <div>
                            <h1>Orientación Profesional PCI IES Ramón y Cajal</h1>

                            <h2>
                                Desde IES Ramón y Cajal ponemos a tu disposición información importante para la
                                realización de los
                                principales trámites
                            </h2>
                            <a
                                className={"pdf"}
                                href={"/downloads/orientacion-academico-profesional-PCI.pdf"}
                                rel="noreferrer"
                                target={"_blank"}
                            >
                                Esquema de orientación académico profesional.
                            </a>
                        </div>
                        <div className={"is-relative"}>
                            <div className={"video--bloque--a"}/>
                            <LazyIframe src={"oBcuXX6QSJw"} title={"La vida de Dani"} />
                        </div>
                    </div>
            </section>
            <section>
                <div className={"container o-orientation-professional"}>
                    <div>
                        <h2>Principales trámites</h2>
                        <h3>Trámites INAEM cualificación profesional</h3>
                        <Procedures procedures={data.allTramitesInaemJson.nodes}/>
                        <h3>Procedimiento Reconocimiento minusvalía IASS</h3>
                        <p>
                            El reconocimiento del grado de discapacidad tiene como finalidad garantizar el
                            acceso del ciudadano a
                            los beneficios y recursos que los organismos públicos proporcionan (mayor o igual al
                            33%)
                        </p>
                        <p>
                            Par el reconocimiento de la discapacidad se valoran varios factores (discapacidad,
                            factores sociales,
                            entorno familiar, situación laboral educativa y cultural)
                        </p>
                        <Procedures procedures={data.allMinusvaliaIassJson.nodes}/>
                        <h3>Formación en centros de trabajo</h3>
                        <p>
                            Durante el tercer trimestre del segundo curso, el alumnado realiza un periodo de
                            prácticas (240 horas)
                            en empresas del sector. Muchas de las empresas son centros especiales de empleo,
                            talleres
                            ocupacionales o empresas normalizadas acostumbradas a contratar personas con
                            discapacidad. Es el
                            tutor/a del segundo curso el que decide, según las características del alumno/a, la
                            empresa a la que
                            irá.
                        </p>
                    </div>
                    <Attachments docs={data.allFile.nodes}/>
                </div>
            </section>
            <PcisBanners/>
            <Map>
                <h2>¿Necesitas más información sobre nuestros PCI?</h2>
                <p>
                    Si tienes más dudas, envía un correo electrónico a la siguiente dirección{" "}
                    <a href={"mailto:pci.ryc.zgz@gmail.com"} target={"_blank"} rel={"noopener noreferrer"}>
                        {" "}
                        pci.ryc.zgz@gmail.com{" "}
                    </a>{" "}
                    o rellena el siguiente formulario de información
                </p>
                {/*<ContactWithForm/>*/}
            </Map>
        </main>
            </div>
        </Layout>
</>
)
    ;
};

export default PageOrientacionProfesional;

export const query = graphql`
  query TramitesInaem {
    allTramitesInaemJson {
      nodes {
        answer
        question
      }
    }
    allMinusvaliaIassJson {
      nodes {
        answer
        question
      }
    }
    allFormacionCentrosTrabajoJson {
      nodes {
        answer
        question
      }
    }
    allFile(filter: { sourceInstanceName: { eq: "docs-tramites" } }) {
      nodes {
        name
        relativePath
        extension
      }
    }
  }
`;
