import "./attachments.scss";

import * as React from "react";
import { PropsWithChildren } from "react";

interface Doc {
  name: string;
  relativePath: string;
  extension: string;
}

interface PropsAttachments extends PropsWithChildren<any> {
  docs: Doc[];
}

const Attachments: React.FC<PropsAttachments> = (props: PropsAttachments) => {
  function normalizeTextWithoutHyphen(text) {
    return text.replace(/[-|_]/g, " ").toLowerCase();
  }
  const items_render: JSX.Element[] = [];
  props.docs.map((document, index) =>
    items_render.push(
      <li key={index}>
        <a
          href={`/downloads/general/${document.relativePath}`}
          title={`Descargar ${document.relativePath}`}
          target={"_blank"}
          rel={"noreferrer noopener"}
        >
          {normalizeTextWithoutHyphen(document.name)}
        </a>
      </li>
    )
  );
  return (
    <div className={"o-attachments"}>
      <p className={"h3 a-attachments__title"}>Documentos</p>
      <ul className={"m-attachments__list"}>{items_render}</ul>
    </div>
  );
};

export default Attachments;
