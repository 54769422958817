import * as React from "react";

const toggle_svg = (
  <svg width="12" height="7" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.00001 9C6.74472 8.99871 6.49291 8.94061 6.26287 8.82991C6.03282 8.71922 5.83031 8.5587 5.67001 8.36L1.46001 3.26C1.214 2.95297 1.05919 2.583 1.01323 2.19227C0.967256 1.80153 1.03198 1.40574 1.20001 1.05C1.33629 0.740826 1.55868 0.477413 1.84063 0.291223C2.12257 0.105032 2.45215 0.00393305 2.79001 0H11.21C11.5479 0.00393305 11.8774 0.105032 12.1594 0.291223C12.4413 0.477413 12.6637 0.740826 12.8 1.05C12.968 1.40574 13.0328 1.80153 12.9868 2.19227C12.9408 2.583 12.786 2.95297 12.54 3.26L8.33001 8.36C8.16971 8.5587 7.96719 8.71922 7.73715 8.82991C7.5071 8.94061 7.2553 8.99871 7.00001 9Z"
      fill="#6a95d8"
    />
  </svg>
);

export { toggle_svg };
