import "./procedures.scss";

import * as React from "react";
import { PropsWithChildren, useState } from "react";

import { toggle_svg } from "./elements_procedure";

interface Procedure {
  question: string;
  answer: string[];
}

interface PropsProcedures extends PropsWithChildren<any> {
  procedures: Procedure[];
}

const Procedures: React.FC<PropsProcedures> = (props: PropsProcedures) => {
  const { procedures } = props;

  const [selected, setSelected] = useState(null);
  const toggle = (i) => {
    if (selected == i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  return (
    <section className={"o-procedures container"}>
      {procedures.map((procedure, index) => (
        <div key={index} className={"m-procedures__item"}>
          <h3 onClick={() => toggle(index)} className={"a-procedures__question"}>
            <span className={`a-procedures__toggle ${index === selected ? "a-procedures__toggle--active" : ""}`}>
              {toggle_svg}
            </span>
            {procedure.question}
          </h3>
          <ol type={"a"} className={`m-procedures__answer ${index === selected ? "m-procedures__answer--active" : ""}`}>
            {procedure.answer.map((text, index) => (
              <li key={index}>{text}</li>
            ))}
          </ol>
        </div>
      ))}
    </section>
  );
};

export default Procedures;
